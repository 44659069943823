@import "~antd/dist/antd.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* .ant-tabs-nav-list .ant-tabs-tab{
 padding:10px 12px;
 background-color:#cc3300;
 color:#fff;
} */
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: rgba(225, 225, 225, 0.80);
}

.ant-tabs-ink-bar {
  background-color: rgba(0, 0, 0, 0.30);
  height: 3px !important;
}

.ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 2px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  background-color: #f1f1f1;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header {
  background-color: #ffffff;
  border-bottom: 1px solid #dedede;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  z-index: 1;
  width: 100%;
}

.ant-layout-footer {
  
  position: fixed;
  z-index: 9;
  right: 0;
  width: 100%;
  bottom: 0;
  padding: 10px;
  background: #000000;
  color: #ffffff;
  text-align: center;
}

.ant-layout {
  background-color: #f1f1f1;
}

.brand {
  font-size: 20px;
  margin: 10px 10px 0px;
  margin-top: 10px;
  flex: 1;
  font-weight: 400;
}

.ant-btn {
  border-radius: 5px;
}

.ant-btn-primary {
  color: #fff;
  border-color: #830051;
  background: #830051;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);

}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: #830051;
  background: #830051;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-layout-sider {
  background-color: #fff;
  float: left;
  height: 100%;
}

.ant-menu-submenu-selected,
.ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #830051;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover .ant-menu-light .ant-menu-item:hover .ant-menu-submenu-title,
.ant-menu-light .ant-menu-item-active .ant-menu-submenu-title,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open .ant-menu-submenu-title,
.ant-menu-light .ant-menu-submenu-active .ant-menu-submenu-title,
.ant-menu-light .ant-menu-submenu-title:hover .ant-menu-submenu-title {
  color: #830051;
}

.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
  color: #830051;

}

.ant-menu-item:active,
.ant-menu-submenu-title:active,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: rgba(196, 12, 124, 0.0) !important
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #830051;
  border-color: #830051;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #830051;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-color: transparent;
}

.ant-checkbox-checked::after {
  border-color: #830051;
  height: 97%;
}

.adminForm {
  padding: 20px;
  max-width: 500px;
}

.ant-layout-has-sider .sidebar {
  position: relative;

}

.ant-layout {}

/*.ant-layout .sidebar .ant-btn{
  z-index: 1;
  margin-bottom: 16px;
  max-width: 46px;
  position: absolute;
   right: -46px;
  left:0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
} */
.ant-layout .sidebar {
  max-width: 35px;
  height: 32px;
  background: none;
  border: 0px;
}

.sidebar .ant-layout-sider {
  border-right: 1px solid #f0f0f0;
}

.ant-layout.ant-layout-has-sider .sidebar {
  max-width: unset;
  height: auto;
}

.ant-layout.ant-layout-has-sider .sidebar .ant-btn {
  float: right;
  padding: 4px 10px
}

.ant-menu-inline {
  border-right: 0px;
}

.sidebar {
  border-right: 1px solid #f0f0f0;

  background-color: #fff;
}

.ant-tabs-nav {
  margin-top: 20px !important;
  margin-bottom: 0px !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab:hover .ant-tabs-tab-btn,
.ant-tabs-tab:hover .ant-tabs-tab-btn:hover,
.ant-tabs-tab .ant-tabs-tab-btn:hover {

  color: #fff;
}

.ant-tabs-tab.ant-tabs-tab-active,
.ant-tabs-tab:hover {
  background-color: #830051 !important;
}

.ant-layout-content {
  background-color: #f1f1f1;
}

.ant-tabs-content-holder {
  background-color: #fff;
}

.cardCls:hover {
  background-color: #830051;
  color: #fff;
  cursor: pointer;
}

.edit-ctn .ant-typography,
.delete-ctn,
.ant-dropdown-trigger.ant-table-filter-trigger.ant-dropdown-open span,
.ant-dropdown-trigger.ant-table-filter-trigger.active span,
.ant-dropdown-menu-item.ant-dropdown-menu-item-selected span,
.ant-table-filter-dropdown-btns button.ant-btn-link {
  color: #830051 !important;
}

.ant-table-column-title,
th.ant-table-cell,
.ant-table-column-sorters:hover .ant-table-column-sorter {
  color: #ffffff !important;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active {
  background: rgba(196, 12, 124, 0.05) !important;
}

.flex-end-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top:20px
}

.clear-btn:focus,
.clear-btn:active {

  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus,
.ant-select-selection-search-input:hover,
.ant-select-selection-search-input:focus,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #830051;
  box-shadow: 0 0 0 2px rgb(131 0 81 / 20%);
}

.ant-menu-title-content {
  color: #000000 !important;
}

.ant-menu-title-content:hover,
.ant-menu-vertical:hover .ant-menu-title-content {
  color: #830051 !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {

  border-color: #830051;
}

.clear-btn:hover {
  border-color: #830051;
  color: #830051;
}

.admin-search {
  border-color: #830051 !important;
}

.ant-divider-inner-text {
  color: #830051;
  font-size: 20px;
}

.selectYear {
  margin-bottom: 20px;
  margin-right: 20px
}

.selectYear>span {
  font-size: 20px;
  font-weight: bold;
}

.selectYear .ant-select-selector {
  min-width: 200px;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: rgba(131, 0, 81, 0.10);
  color: #830051;
}

.brandTitle.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 0%;
}

.ant-layout-sider-children .ant-menu-submenu.ant-menu-submenu-open>ul.ant-menu-hidden {
  max-height: 0px;
  overflow: hidden;
}

.ant-layout-sider-children .ant-menu-submenu.ant-menu-submenu-open>ul {}



::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid #555555;
  border-radius: 10px;
}

/* footer start here */

/* footer start here */
/* header start here */
.userinfo-ctn {
  padding-right: 20px !important;
}

.header-logo {
  padding-left: 20px !important;
  margin-left: 0px !important;
}

.userinfo-ctn>ul {
  border: 0px;
}

.userinfo-ctn>ul li:first-child {
  padding: 0px !important;
  margin-left: 15px;
}

.userinfo-ctn .ant-menu-horizontal>.ant-menu-item::after,
.userinfo-ctn .ant-menu-horizontal>.ant-menu-submenu::after {
  left: 0px;
  right: 0px;
}

.userinfo-ctn .ant-menu-title-content {
  margin-left: 0px !important;
}

/* header end here */
.chart-ctn  {
  margin-top: 30px;}
.chart-ctn .ant-collapse-header {
  
  background: rgb(132, 1, 81);
  background: linear-gradient(90deg, rgba(132, 1, 81, 1) 0%, rgba(132, 1, 81, 1) 40%, rgba(178, 73, 46, 1) 100%);
}

.ant-collapse-header,
.ant-collapse-header span {
  color: #fff !important;
  font-size: 17px !important;
}

.chart-ctn .ant-collapse-item-active span,
.chart-ctn .ant-collapse-item-active .ant-collapse-header {
  color: #830051;
}

.home-content {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 2px 10px 3px #b1acac;
}

.brandtitle {
  background: rgb(132, 1, 81);
  background: linear-gradient(90deg, rgba(132, 1, 81, 1) 0%, rgba(132, 1, 81, 1) 40%, rgba(178, 73, 46, 1) 100%);
  min-height: auto;
  border-radius: 15px 15px 0 0;

}

.ant-modal-header {
  background: rgb(132, 1, 81) !important;
  background: linear-gradient(90deg, rgba(132, 1, 81, 1) 0%, rgba(132, 1, 81, 1) 40%, rgba(178, 73, 46, 1) 100%) !important;
}

.ant-modal-header .ant-modal-title,
.ant-modal-close span .ant-modal-close-icon {
  color: #fff !important;
}

.ant-table-thead th.ant-table-column-has-sorters:hover,
.ant-table-thead th.ant-table-column-sort {
  background: rgba(132, 1, 81, 0.95) !important;
}

.ant-table-thead th {
  background: rgba(132, 1, 81, 1) !important;
}

.ant-modal-footer button,
.ant-btn:hover,
.ant-btn:focus {
  border-color: #8c8c8c;
}

.ant-input:hover {
  border-color: #8c8c8c !important;
}

.ant-form-item-explain-error,
.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color: #830051;
}

.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  border-color: #ff4d4f  !important;
}
.edit-items {width:134px;}
.edit-items a{
  padding: 5px 12px !important;}
  .edit-items .ant-typography{border: 1px solid rgb(131, 0, 81);}
  .edit-items .ant-typography:hover,.edit-items .ant-typography:visited, .edit-items .ant-typography:active {border: 1px solid rgb(131, 0, 81); background-color: transparent !important; color: #830051 !important;}
.cancel-btn.ant-btn:hover, .cancel-btn.ant-btn:focus, .ant-popover-buttons button:first-child:hover { color:#fff; background-color: #8c8c8c; border-color: #8c8c8c !important;}
.ant-input:focus,
.ant-input-focused {
  border-color: #8c8c8c !important;
  box-shadow: 0 0 0 2px rgba(140, 140, 140, 0.2) !important;
  border-right-width: 1px !important;
}

.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #830051;
  border-color: #830051;
}

.ant-pagination-item-active,
.ant-pagination-item:focus-visible,
.ant-pagination-item:hover {
  border-color: #830051;
}

.ant-pagination-item-active a,
.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
  color: #830051;
}

.edit-ctn a.ant-typography,
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #8c8c8c !important;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>td {
  border-right: 0px !important;
}

.tab-inner-content {
  margin: 20px;
}

.backbtn {
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  border: 0px;
  border-bottom: 1px solid transparent;
  color: #830051;
  padding: 0px 5px 0px 0px;
  margin-right: 20px;
  background-color: transparent;
}

.backbtn:hover {
  cursor: pointer;
  border-bottom: 1px solid #830051;
}

.fixedbutton {
  background: #830051;
  font-size: 24px;
  font-weight: 500;
  margin-right: 10px;
  border-radius: 50%;
  color: #fff;
  border: 2px solid #830051;
  position: fixed;
  top: 65px;
  right: -5px;
  height: 45px;
  width: 45px;
  text-align: center;
  display: none;
}

.fixedbutton span {
  margin-left: -4px;
}

header .ant-menu-sub .ant-menu-item:hover {
  background-color: #830051 !important;
  color: #ffffff !important;
}

.responsive-table {
  position: relative;
}

.sidebar .ant-checkbox-group,
.sidebar .ant-checkbox-group li,
.sidebar .ant-checkbox-group li span.ant-menu-title-content,
.sidebar .ant-checkbox-group li span.ant-menu-title-content label.ant-checkbox-wrapper {
  width: 100%;
}

.sidebar .ant-checkbox-group li span.ant-menu-title-content label.ant-checkbox-wrapper span {
  display: inline-block !important;
}

.sidebar .ant-checkbox-group li span.ant-menu-title-content label.ant-checkbox-wrapper span:nth-child(2) {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
 .ant-checkbox-checked::after{
  border-color: #830051 !important;
  border:0px !important
}
/*
.downloadcsv {
  position: absolute !important;
  bottom: 10px !important;
}*/
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after
{border-color: #830051 !important;}
.no-data {min-height: 260px;
text-align: center;
font-size: 18px;
display: table;
width: 100%;
}

.no-data div{text-align: center;
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  /* padding: 50% 0px; */
  width: 100%;
}

.tab-inner-content .clear-frt{
  position: absolute;
  top: 0px;
  right: 0px;
  color: rgba(0, 0, 0, 0.85) !important;
  border-color: #d9d9d9 !important;
}

.tab-inner-content .clear-frt:hover, 
.tab-inner-content .clear-frt:focus,
.tab-inner-content .clear-frt:focus{
  background-color:#d9d9d9 !important ;
  border-color:#d9d9d9 !important; 
  color: rgba(0, 0, 0, 0.85) !important;
}
.responsive-table .table-save{   
  background: "#830051";
  color: "#fff";
  padding: "7px 24px";
  border-color: "#830051";
  font-size: "14px";
text-align: right; 
margin-bottom: 22px;}
.tab-inner-content .clear-frt:hover, 
.tab-inner-content .clear-frt:active, 
.tab-inner-content .clear-frt:focus, 
.tab-inner-content .clear-frt:visited {
  background-color:#8c8c8c !important ;
  border-color:#8c8c8c !important; color: #fff !important; }
.downloadcsv.ant-btn
{ color: #fff !important;
border-color: #830051 !important;
background-color: #830051 !important;}
.downloadcsv.ant-btn:focus, .downloadcsv.ant-btn:active,  .downloadcsv.ant-btn:hover
{ color: #830051 !important;
border-color: #830051 !important;
background-color: transparent !important;}
.search-ctn .clear-btn{ color: rgba(0, 0, 0, 0.89) !important;
  border-color: rgba(0, 0, 0, 0.25) !important;
  background-color:#fff;}
  .ant-input-group-addon button { color: #fff!important;
    border-color: #830051!important;
    background-color:#830051;}
  .search-ctn .clear-btn:hover, .search-ctn .ant-input-group-addon button:hover{
    background-color:#fff !important; cursor: pointer !important; color: #830051 !important;}
  .responsive-table .table-save { background-color:"#830051" !important;
    color:"#fff" !important; border-color:"#830051" !important ; height:auto !important; padding:7px 10px !important; font-size: 14px !important;}
.ant-pagination-item:hover a { color: #830051 !important;}
.ant-pagination-prev:focus-visible .ant-pagination-item-link, .ant-pagination-next:focus-visible .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link , .ant-pagination-item-active a, .ant-pagination-item:focus-visible, .ant-pagination-item:hover, .ant-pagination-item-active {border-color: #830051 !important; color: #830051 !important;}
.editable-cell-value-wrap {border:1px solid #fff}
.ant-message-notice-content span{
  font-size: 18px !important;
}
.ant-picker-focused, .ant-picker:hover, .ant-picker-focused {
  box-shadow: 0 0 0 2px rgb(131 0 81 / 20%) !important;
  border-color: rgba(0, 0, 0, 0.55) !important;
  border-color: rgba(0, 0, 0, 0.55) !important;
  }
.edit-ctn {}
.ant-dropdown-trigger.ant-table-filter-trigger{display: none !important;}
.ant-picker-header-view button:hover{color: #830051 !important;}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner
{background: #830051 !important ; color: #fff !important;}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {color: #fff !important; background-color: #830051 !important;}
.ant-picker-today-btn{color: #830051!important; }
.ant-picker-footer:hover .ant-picker-today-btn{color: #fff !important;}
.ant-picker-footer:hover{background-color: #830051;}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before { border-color:#830051 !important;}
.delete-ctn span{cursor: pointer;}
.ant-message-custom-content .ant-message-info, .ant-message-custom-content  {
  padding:5px 15px !important;
}
.ant-select-selection-placeholder, .ant-select-arrow .anticon > svg{color:rgba(0, 0, 0) !important;}
.otherfilters .ant-select:not(.ant-select-customize-input) .ant-select-selector{color:#666666;
  border-radius: 5px !important;
  border-width: 1px !important; border-color:rgb(255, 153, 0) !important;}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector, .ant-select-focused .ant-select-selector, .ant-select-open .ant-select-selector{      box-shadow: 0 0 0 2px rgb(131 0 81 / 20%) !important;  border-color: rgba(0, 0, 0, 0.55) !important; border-color: rgba(0, 0, 0, 0.55) !important;}
.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25) !important;
  border-color: #d9d9d9 !important;}
  .search-ctn{position: relative; padding-bottom: 15px;z-index: 9;}
  .ant-message {left: inherit; right: 0px !important; top:80px !important}
  .ant-message-notice {text-align: right !important; margin-right: 10px !important; }
  .search-ctn .flex-middle-container{
    position: absolute;
    top: 0px;
    left: 1px;
  }
  .ant-btn-primary, .ant-btn-primary:focus, .ant-btn-primary:hover {
    background: #830051 !important;
    border-color: #830051 !important;
    color: #fff;
} 
.ant-alert.ant-alert-warning.ant-alert-no-icon.ant-alert-banner{
  position: absolute;
    top: -157px;
    right: 0px;
}
.admin-table-view  .downloadcsv{}
/*.downloadcsv {position: absolute !important;
  top: -2px;
  right: 58px;}
  
.admin-table-view-brands  .downloadcsv{
  top: 21px;
    right: 148px;
}
.financialcalendar-container.admin-table-view{ 

}*/
#timelineViewChart .amcharts-amexport-menu li a{ background-color: #830051; color: #fff; padding-bottom: 7px;}
.ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-inner:after {
  color: #ffff !important;
 
}
.ant-checkbox-checked::after, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{border-color:#830051;}
.ant-checkbox-checked .ant-checkbox-inner {background-color: #830051 !important; border-color: #830051 !important;}
.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {border:0px !important}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {color: #830051 !important;}
.ant-checkbox-inner {border-color: #d9d9d9  !important;}
.sidebar .ant-menu-root{height: 100%;}
.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-active i, .ant-menu-submenu.ant-menu-submenu-active.ant-menu-submenu-inline:hover i{ color:#830051 !important}
#timelineViewChart .amcharts-amexport-menu li a.amcharts-amexport-clickable{padding-bottom: 10px !important;}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {color: #fff !important }
.ant-table-cell-row-hover .editable-cell-value-wrap,  .editable-cell-value-wrap {width:104px;     padding: 4px 12px;}
table th:nth-child(6), th:nth-child(7), th:nth-child(8), table td:nth-child(6), td:nth-child(7), td:nth-child(8) {
  width: 155px;
}
.ant-table-cell .ant-picker:hover, .ant-table-cell .ant-picker-focused {
  border-color: #eee;}
  .ant-table-cell .ant-picker-focused {
    border-color: #eee;
    box-shadow: 0 0 0 2px hwb(210 53% 45% / 0.2);}
/*bug css */
.ant-btn:hover {
  cursor: pointer !important;
}
.otherfilters .ant-select{margin-bottom: 20px;}