.editable-cell {
    position: relative;
  }
  
  .editable-cell-value-wrap {
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
  }
  
  .editable-row:hover .editable-cell-value-wrap {
    /* padding: 4px 11px; */
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
  
  [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }
  .ant-table-cell .editable-cell-value-wrap.dateformatdiv {
    padding-right: 7px !important;
    padding-left: 7px !important;
}